import { useLocalObservable } from 'mobx-react'
import { computedFn } from 'mobx-utils'
import { useContext } from 'react'

import { Bookings } from '@/types/types'
import StoresContext from '@/utils/StoresContext'

const useAnnotateFilters = () => {
  const { appStore } = useContext(StoresContext)

  const state = useLocalObservable(() => ({
    isCartValidWithShiftSlotPaxRoomAndSelectedHighlightedOffer: computedFn(
      (shift: Bookings.Shift, slot: Bookings.Slot, pax: number, roomId: number | null) => {
        const {
          selectedHighlightedOffer: offer,
          computeOfferFromShiftAndSlot,
          isCartValidWithOffersShiftSlotPaxAndRoom
        } = appStore.state
        if (!offer) {
          return false
        }
        try {
          const offers = [
            {
              ...computeOfferFromShiftAndSlot(offer.id, shift, slot),
              count: pax
            }
          ]
          return isCartValidWithOffersShiftSlotPaxAndRoom(offers, shift, slot, pax, roomId)
        } catch (e) {
          return false
        }
      }
    ),
    isPaxCompatibleWithSelectedHighlightedOffer: computedFn((pax: number) => {
      const { selectedHighlightedOffer: offer, wish, currentDay } = appStore.state

      if (!offer || !currentDay) {
        return false
      }
      // loop through available slots
      return currentDay.shifts.some((shift) => {
        return shift.shift_slots.some((slot) => {
          return state.isCartValidWithShiftSlotPaxRoomAndSelectedHighlightedOffer(shift, slot, pax, wish.room_id)
        })
      })
    }),
    isRoomIdCompatibleWithSelectedHighlightedOffer: computedFn((roomId: number) => {
      const { selectedHighlightedOffer: offer, wish, currentDay } = appStore.state

      if (!offer || !currentDay) {
        return false
      }
      // loop through available slots
      return currentDay.shifts.some((shift) => {
        return shift.shift_slots.some((slot) => {
          return state.isCartValidWithShiftSlotPaxRoomAndSelectedHighlightedOffer(shift, slot, wish.pax, roomId)
        })
      })
    }),
    isShiftAndSlotCompatibleWithSelectedHighlightedOffer: computedFn((shift, slot) => {
      const { selectedHighlightedOffer: offer, wish } = appStore.state
      if (!offer) {
        return false
      }
      return state.isCartValidWithShiftSlotPaxRoomAndSelectedHighlightedOffer(shift, slot, wish.pax, wish.room_id)
    })
  }))

  return state
}

export default useAnnotateFilters
