import { Stack } from '@zenchef/styled-system/jsx'
import { observer } from 'mobx-react-lite'

import { Bookings } from '@/types/types'

import ShiftHeader from './ShiftHeader'
import SlotButton from './SlotButton'
import useSlotSelector from './useSlotSelector'

interface SlotInputProps {
  onSlotSelected?: (slot: Bookings.Slot, shift: Bookings.Shift, isWaitlist: boolean) => void
}

const SlotInput = observer(({ onSlotSelected }: SlotInputProps): JSX.Element => {
  const { shifts, getAllAvailableSlots } = useSlotSelector()
  return (
    <Stack gap='gap.4' pb='padding.4' width='100%'>
      {shifts.map((shift) => {
        const allSlots = getAllAvailableSlots(shift)
        return (
          <Stack gap='gap.1' key={shift.id}>
            <ShiftHeader shift={shift} />
            {allSlots.map((slot) => {
              return <SlotButton key={slot.name} slot={slot} shift={shift} onSlotSelected={onSlotSelected} />
            })}
          </Stack>
        )
      })}
    </Stack>
  )
})

export default SlotInput
