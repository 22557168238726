import { Badge, Tag, Text } from '@zenchef/ds-react'
import { Flex, HStack, styled } from '@zenchef/styled-system/jsx'
import { observer } from 'mobx-react'

import SelectableItem from '@/components/redesign/common/SelectableItem'
import useAnnotateFilters from '@/components/redesign/filters/useAnnotateFilters'
import HighlightedExperienceBadge from '@/components/redesign/offers/HighlightedExperiences/HighlightedExperienceBadge'
import { Bookings } from '@/types/types'
import { useTranslation } from '@/utils/hooks'

import useSlotSelector from './useSlotSelector'
type Status = 'available' | 'waitlist'

const SlotButtonStyledAsSlotInput = styled(SelectableItem, {
  variants: {
    isWaitlist: {
      true: {
        borderStyle: 'dashed',
        _selected: {
          backgroundColor: 'background.functional.warning.bold',
          border: 'none',
          color: 'content.neutral.reverse.bold',
          _hover: {
            backgroundColor: 'background.functional.warning.bold'
          }
        },
        _focusVisible: {
          backgroundColor: 'background.brand.bold',
          borderColor: 'border.functional.warning',
          boxShadow: 'effect.focus-ring.warning'
        }
      }
    }
  }
})

interface SlotStatusProps {
  status: Status
}

export const SlotStatus = ({ status }: SlotStatusProps): JSX.Element | null => {
  switch (status) {
    case 'available':
      return <Badge chip function='success' outlined />
    case 'waitlist':
      return <Badge chip function='warning' outlined />

    default:
      return null
  }
}

interface SlotButtonProps {
  slot: Bookings.Slot
  shift: Bookings.Shift
  onSlotSelected?: (slot: Bookings.Slot, shift: Bookings.Shift, isWaitlist: boolean) => void
}

const SlotButton = observer(({ slot, shift, onSlotSelected }: SlotButtonProps): JSX.Element => {
  const {
    getSlotStatus,
    getSlotUntil,
    selectSlot,
    formatSlot,
    formatTime,
    formattedSlotNameSelected,
    hasDisplayEndTimeAcl
  } = useSlotSelector()

  const { isShiftAndSlotCompatibleWithSelectedHighlightedOffer } = useAnnotateFilters()
  const { t } = useTranslation()

  const status = getSlotStatus(slot, shift)
  const until = getSlotUntil(slot, shift)
  const isWaitlist = status === 'waitlist'
  const shouldDisplayEndTime = slot.capacity?.show_turn_times && !isWaitlist && hasDisplayEndTimeAcl

  const name = formatSlot(slot)
  const isSelected = formattedSlotNameSelected === name

  const isAnnotated = isShiftAndSlotCompatibleWithSelectedHighlightedOffer(shift, slot)

  return (
    <SlotButtonStyledAsSlotInput
      onClick={() => {
        if (selectSlot(slot, shift, isWaitlist)) {
          onSlotSelected?.(slot, shift, isWaitlist)
        }
      }}
      selected={isSelected}
      isWaitlist={isWaitlist}
      testId={`slot-${status}-${name}`}
      position='relative'>
      <HStack justify='space-between' width='100%'>
        <Flex align='center' gap='gap.2'>
          {isSelected ? null : <SlotStatus status={status} />}
          <Text>{name}</Text>
        </Flex>
        {isWaitlist ? (
          <Tag hierarchy={isSelected ? 'bold' : 'subtlest'} colorTheme={isSelected ? 'neutral' : 'reversed'}>
            {t('waitlist')}
          </Tag>
        ) : shouldDisplayEndTime ? (
          <Tag hierarchy={isSelected ? 'bold' : 'subtle'} colorTheme={isSelected ? 'neutral' : 'reversed'}>
            {t('until', { until: formatTime(until) })}
          </Tag>
        ) : null}
      </HStack>
      {isAnnotated ? <HighlightedExperienceBadge position='absolute' top='-1px' right='-1px' /> : ''}
    </SlotButtonStyledAsSlotInput>
  )
})

export default SlotButton
